<template>
  <a-modal
    v-model="visible"
    title="添加班次"
    :mask-closable="false"
    @ok="handleSubmit"
    :confirmLoading="loading"
    :width="800"
  >
    <a-form
      class="ant-advanced-search-form"
      :form="form"
      @submit="handleSubmit"
      v-bind="formItemLayout"
    >
      <a-form-item label="复制已有班次信息">
        <a-select placeholder="请选择" v-model="data.id" @change="handleChange">
          <a-select-option
            v-for="item in classSelectList"
            :value="item.id"
            :key="item.id"
          >
            {{ item.title }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="班次名称">
        <a-input
          placeholder="请输入"
          v-decorator="['title', validatorRules.title]"
        />
      </a-form-item>
      <a-form-item label="班次容量">
        <a-input-number
          v-decorator="['contain_num', validatorRules.contain_num]"
          :formatter="
            (value) => {
              if (typeof value === 'string') {
                return !isNaN(Number(value))
                  ? value.replace(/^(0+)|[^\d]/g, '')
                  : '';
              } else if (typeof value === 'number') {
                return !isNaN(value)
                  ? String(value).replace(/^(0+)|[^\d]/g, '')
                  : '';
              } else {
                return '';
              }
            }
          "
          :min="1"
        />
        人
      </a-form-item>
      <a-form-item label="培训地点">
        <a-input
          placeholder="请输入"
          v-decorator="['address', validatorRules.address]"
        />
      </a-form-item>
      <a-form-item label="培训日期">
        <a-range-picker
          @change="onChangePxrq"
          :disabled-date="disabledDate"
          v-decorator="['pxTime', validatorRules.pxTime]"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        />
      </a-form-item>
      <a-form-item label="报到时间">
        <a-range-picker
          v-decorator="['bdTime', validatorRules.bdTime]"
          :show-time="{ format: 'HH:mm' }"
          format="YYYY-MM-DD HH:mm"
          @change="changebdTime"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        />
      </a-form-item>
      <a-form-item label="报到地点">
        <a-input
          disabled
          placeholder="请输入"
          v-decorator="['reg_address', validatorRules.reg_address]"
        />
        <a-button type="primary" @click="selectClockInAddress">
          选择报到地点
        </a-button>
      </a-form-item>
      <a-form-item label="住宿地点">
        <a-input placeholder="请输入" v-model="data.hotel_address"/>
      </a-form-item>
      <a-form-item label="讲师">
        <a-select
          placeholder="请选择"
          v-decorator="['teacher_id', validatorRules.teacher_id]"
          v-model="data.teacher_id"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="item in lecturerSelectList"
            :value="item.id"
            :key="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="联系人">
        <a-input placeholder="请输入" v-model="data.contract"/>
      </a-form-item>
      <a-form-item label="联系人电话">
        <a-input placeholder="请输入" v-model="data.contract_phone"/>
      </a-form-item>
      <a-form-item label="图片">
        <a-button type="primary" @click="addPic" ghost> 添加图片组</a-button>
        <template v-if="visible">
          <uploadPicsList
            v-for="(item, index) in addPicList"
            :ref="'picList' + index"
            :key="index"
            :index="index"
            :data="item"
            :maxNum="1"
            @delete="deleteUploadPic"
            @saveImgList="saveImgList"
            @uploadLoainng="onUploadLoainng"
          ></uploadPicsList>
        </template>
      </a-form-item>
    </a-form>
    <selectMap
      ref="clockIn"
      :title="newTitle"
      :latLng="latLng"
      @success="onSuccess"
    ></selectMap>
  </a-modal>
</template>

<script>
import {
  getClassDetail,
  saveClassDetail,
  getClassSelectList,
  getLecturerSelectList,
} from '@/api/config';
import moment from 'moment';
import { ConfigMixin } from '../mixins/configMixins';
import { geocoder } from '@/components/map/api/commonMap';

export default {
  name: 'addClassLayer',
  mixins: [ConfigMixin],
  watch: {
    data: {
      handler() {
      },
    },
  },
  components: {
    uploadPicsList: () => import('./uploadPicsList'),
    selectMap: () => import('../components/selectMap'),
  },
  data() {
    return {
      visible: false,
      form: this.$form.createForm(this),
      formItemLayout: {
        labelCol: { span: 5 },
        wrapperCol: { span: 17 },
      },
      typeId: '',
      classSelectList: [],
      validatorRules: {
        title: {
          rules: [{ required: true, message: '请输入班次名称!' }],
        },
        contain_num: {
          rules: [{ required: true, message: '请输入班次容量!' }],
        },
        address: {
          rules: [{ required: true, message: '请输入培训地点!' }],
        },
        bdTime: {
          rules: [{ required: true, message: '请选择报到时间!' }],
        },
        pxTime: {
          rules: [{ required: true, message: '请选择培训时间!' }],
        },
        reg_address: {
          rules: [{ required: true, message: '请输入报到地点!' }],
        },
        teacher_id: {
          rules: [{ required: true, message: '请选择讲师!' }],
        },
      },
      addPicList: [],
      data: {
        hotel_address: '',
        teacher_id: '',
        contract: '',
        contract_phone: '',
      },
      imgList: [],
      lecturerSelectList: [],
      sign_start_date: '',
      sign_end_date: '',
      thisId: 0,
      address: '',
      latLng: undefined,
      address_coor: undefined,
      newTitle: '选择报到地点',
    };
  },
  created() {
    this.getClassSelectList();
    this.getLecturerSelectList();
  },
  methods: {
    moment,
    openMe(id, data) {
      this.thisId = data?.id;
      id && data?.id
        ? (this.getData(data.id), (this.training_id = data?.id))
        : this.setDefaultData();
      this.typeId = id;
      this.visible = true;
      this.getClassSelectList();
      this.getLecturerSelectList();
    },
    async getData(id) {
      this.loading = true;
      const data = await getClassDetail(id).finally(
        () => (this.loading = false),
      );
      if (!data || data.error_code) {
        return;
      }
      if (data) {
        let datas = data.data;
        this.$nextTick(() => {
          this.form.setFieldsValue({
            ...datas,
            bdTime: [
              datas?.sign_start_date_fmt != ''
                ? moment(
                `${datas?.sign_start_date_fmt?.split(' ')?.[0]} ${
                  datas.sign_time.split('-')?.[0] ?? ''
                }`,
                )
                : moment(),
              datas?.sign_end_date_fmt != ''
                ? moment(
                `${datas?.sign_end_date_fmt?.split(' ')?.[0]} ${
                  datas.sign_time.split('-')?.[1] ?? ''
                }`,
                )
                : moment(),
            ],
            pxTime: [moment(datas?.start_date), moment(datas?.end_date)],
          });
        });
        this.data = datas;
        this.data.start_date = datas.start_date;
        this.data.end_date = datas.end_date;
        this.data.sign_start_date = `${datas?.sign_start_date_fmt} ${
          datas.sign_time.split('-')?.[0] ?? ''
        }`;
        this.data.sign_end_date = `${datas?.sign_end_date_fmt} ${
          datas.sign_time.split('-')?.[1] ?? ''
        }`;
        this.data.sign_time = datas.sign_time;
        this.data.id = undefined;
        this.data.teacher_id =
          datas.teacher_id > 0 ? datas.teacher_id : undefined;

        this.address_coor = datas.address_coor.split(',');
        this.latLng = datas.address_coor.split(',');
        this.addPicList = datas.images;
      } else {
        this.setDefaultData();
      }
    },
    async getClassSelectList() {
      const data = await getClassSelectList({
        // post_id: this.typeId,
        training_id: this.$route.query.id,
      }).finally(() => (this.loading = false));
      if (!data || data.error_code) {
        return;
      }
      if (data) {
        this.classSelectList = data.data;
      }
    },
    async getLecturerSelectList() {
      const data = await getLecturerSelectList(this.$route.query.id).finally(
        () => (this.loading = false),
      );
      if (!data || data.error_code) {
        return;
      }
      if (data) {
        this.lecturerSelectList = data.data;
      }
    },
    setDefaultData() {
      this.$nextTick(() => {
        this.form.setFieldsValue({
          title: '',
          contain_num: '',
          address: '',
          reg_address: '',
          bdTime: [undefined, undefined],
          pxTime: [undefined, undefined],
        });
      });
      this.clear();
      this.addPicList = [];
    },
    handleOk() {
    },
    handleSubmit() {
      let _this = this;
      _this.form.validateFields((err, values) => {
        let num = _this.addPicList.length;
        for (let i = 0; i < num; i++) {
          let str = 'picList' + i;
          _this.$refs[str][0].saveSingelList('image_id');
        }
        if (!err) {
          values.images = JSON.stringify(_this.imgList);
          values.sign_start_date = this.moment(
            this.data.sign_start_date,
          ).format('YYYY-MM-DD');
          values.sign_end_date = this.moment(this.data.sign_end_date).format(
            'YYYY-MM-DD',
          );
          values.start_date = this.moment(this.data.start_date).format(
            'YYYY-MM-DD',
          );
          values.end_date = this.moment(this.data.end_date).format(
            'YYYY-MM-DD',
          );
          values.sign_time = this.data.sign_time;
          values.address_coor = this.address_coor.toString(); // 培训地址定位坐标(经度,纬度)
          values.reg_address_coor = this.address_coor.toString(); // 报到地点定位坐标(经度,纬度)
          this.training_id
            ? (values.id = this.training_id)
            : (values.training_id = this.$route.query.id),
            (values.post_id = this.typeId);
          values.hotel_address = this.data.hotel_address;
          values.teacher_id = this.data.teacher_id;
          values.contract_phone = this.data.contract_phone;
          values.contract = this.data.contract;
          this.save(values);
        }
      });
    },
    async save(params) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      if (typeof params.images === 'object') {
        params.images = JSON.stringify(
          params.images.map((s) => {
            return {
              title: s?.image_title || s?.title,
              image_id: s?.image_id || s?.image_id,
            };
          }),
        );
      }
      if (this.thisId === undefined) {
        params.id = 0;
        params.training_id = this.id;
      } else {
        params.id = this.thisId;
        params.training_id = this.id;
      }

      const data = await saveClassDetail(params).finally(
        () => (this.loading = false),
      );

      if (!data || data.error_code) {
        this.$message.error({ content: data?.message || '保存失败' });
        return;
      }
      this.$message.success({ content: data?.message || '保存成功' });
      this.$emit('success');
      this.clear();
    },
    clear() {
      this.form.validateFields((err, values) => {
        for (let st in values) {
          values[st] = undefined;
        }
        this.form.setFieldsValue(values);
      });
      this.imgList = [];
      this.data = {
        hotel_address: '',
        teacher_id: '',
        contract: '',
        contract_phone: '',
        // eslint-disable-next-line no-dupe-keys
      };
      this.visible = false;
    },
    handleChange(v) {
      this.getData(v);
    },
    onChange() {
    },
    deleteUploadPic(i) {
      this.addPicList.splice(i, 1);
    },
    saveImgList(data) {
      this.imgList.push(data);
    },
    addPic() {
      this.addPicList.push(0);
    },
    onChangePxrq(d, t) {
      this.data.start_date = t[0];
      this.data.end_date = t[1];
    },
    changebdTime(d, t) {
      this.data.sign_start_date = t[0];
      this.data.sign_end_date = t[1];
      this.data.sign_time =
        t[0].substring(11, 16) + '-' + t[1].substring(11, 16);
    },
    selectClockInAddress() {
      this.$refs.clockIn.openMe();
    },
    async onSuccess(recode) {
      this.address = recode.addr;
      this.latLng = [recode.lng, recode.lat];
      this.address_coor = [recode.lng, recode.lat];
      this.form.setFieldsValue({
        reg_address: recode?.addr,
      });
      if (!recode.addr) {
        const data = await geocoder({
          location: [recode.lat, recode.lng].toString(),
          get_poi: 1,
        });
        if (data?.status === 0) {
          this.reg_address = data?.result?.address;
          this.form.setFieldsValue({
            reg_address: this.reg_address,
          });
        }
      }
    },
    onUploadLoainng(value) {
      this.loading = value;
    },
  },
};
</script>

<style scoped>
</style>
